import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../services/auth';
import { motion } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

function Header() {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-900 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="text-white font-bold text-2xl">
            Digital Signage CMS
          </Link>
          <div className="hidden md:flex space-x-6">
            {isAuthenticated ? (
              <>
                <Link to="/dashboard" className="text-gray-300 hover:text-blue-400 transition">Dashboard</Link>
                <Link to="/content" className="text-gray-300 hover:text-blue-400 transition">Content</Link>
                <Link to="/screens" className="text-gray-300 hover:text-blue-400 transition">Screens</Link>
                <Link to="/playlists" className="text-gray-300 hover:text-blue-400 transition">Playlists</Link>
                <button onClick={handleLogout} className="text-gray-300 hover:text-blue-400 transition">Logout</button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-gray-300 hover:text-blue-400 transition">Login</Link>
                <Link to="/register" className="text-gray-300 hover:text-blue-400 transition">Register</Link>
              </>
            )}
          </div>
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="p-2 rounded-md text-gray-300 hover:bg-gray-800">
              <span className="sr-only">Open menu</span>
              {isMenuOpen ? (
                <FiX className="w-6 h-6" />
              ) : (
                <FiMenu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: isMenuOpen ? 1 : 0, y: isMenuOpen ? 0 : -20 }}
        transition={{ duration: 0.3 }}
        className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden bg-gray-900 border-t border-gray-700`}
      >
        <div className="px-4 py-3 space-y-1">
          {isAuthenticated ? (
            <>
              <Link to="/dashboard" className="block text-gray-300 hover:text-blue-400 transition">Dashboard</Link>
              <Link to="/content" className="block text-gray-300 hover:text-blue-400 transition">Content</Link>
              <Link to="/screens" className="block text-gray-300 hover:text-blue-400 transition">Screens</Link>
              <Link to="/playlists" className="block text-gray-300 hover:text-blue-400 transition">Playlists</Link>
              <button onClick={handleLogout} className="block text-gray-300 hover:text-blue-400 transition w-full text-left">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="block text-gray-300 hover:text-blue-400 transition">Login</Link>
              <Link to="/register" className="block text-gray-300 hover:text-blue-400 transition">Register</Link>
            </>
          )}
        </div>
      </motion.div>
    </header>
  );
}

export default Header;
