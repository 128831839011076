import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getPlaylists, createPlaylist, deletePlaylist, getContent, addContentToPlaylist, removeContentFromPlaylist, reorderPlaylistContent } from '../services/api';
import { FiPlus, FiTrash2, FiFilm, FiImage, FiMusic, FiX, FiChevronDown, FiChevronUp, FiPlay } from 'react-icons/fi';

function PlaylistListPage() {
  const [playlists, setPlaylists] = useState([]);
  const [contents, setContents] = useState([]);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedPlaylist, setExpandedPlaylist] = useState(null);
  const [isAddingContent, setIsAddingContent] = useState(false);

  const fetchPlaylists = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getPlaylists();
      setPlaylists(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching playlists:', error);
      setError('Failed to fetch playlists. Please try again later.');
    }
    setIsLoading(false);
  }, []);

  const fetchContents = useCallback(async () => {
    try {
      const response = await getContent();
      setContents(response.data);
    } catch (error) {
      console.error('Error fetching contents:', error);
    }
  }, []);

  useEffect(() => {
    fetchPlaylists();
    fetchContents();
  }, [fetchPlaylists, fetchContents]);

  const handleCreatePlaylist = async (e) => {
    e.preventDefault();
    if (!newPlaylistName.trim()) return;
    try {
      await createPlaylist({ name: newPlaylistName });
      setNewPlaylistName('');
      fetchPlaylists();
    } catch (error) {
      console.error('Error creating playlist:', error);
      setError('Failed to create playlist. Please try again.');
    }
  };

  const handleReorderContent = async (playlistId, contentId, newOrder) => {
    try {
      const playlist = playlists.find(p => p.id === playlistId);
      const newContents = [...playlist.Contents].sort((a, b) => a.order - b.order);
      const oldIndex = newContents.findIndex(c => c.id === contentId);
      const content = newContents[oldIndex];
      
      // Remove the content from its old position
      newContents.splice(oldIndex, 1);
      // Insert it at the new position
      newContents.splice(newOrder - 1, 0, content);
      
      // Update the order of all items
      const updatedContents = newContents.map((c, index) => ({ ...c, order: index + 1 }));
      
      // Call API to update the order
      await reorderPlaylistContent(playlistId, { contentOrder: updatedContents.map(c => c.id) });
      
      // Update local state
      setPlaylists(playlists.map(p => 
        p.id === playlistId ? { ...p, Contents: updatedContents } : p
      ));
    } catch (error) {
      console.error('Error reordering playlist content:', error);
      setError('Failed to reorder playlist content. Please try again.');
    }
  };

  const handleDeletePlaylist = async (id) => {
    if (window.confirm('Are you sure you want to delete this playlist?')) {
      try {
        await deletePlaylist(id);
        fetchPlaylists();
      } catch (error) {
        console.error('Error deleting playlist:', error);
        setError('Failed to delete playlist. Please try again.');
      }
    }
  };

  const handleAddContent = async (playlistId, contentId) => {
    try {
      const playlist = playlists.find(p => p.id === playlistId);
      const newOrder = playlist.Contents ? playlist.Contents.length + 1 : 1;
      await addContentToPlaylist(playlistId, { contentItems: [{ contentId, duration: 10, order: newOrder }] });
      fetchPlaylists();
    } catch (error) {
      console.error('Error adding content to playlist:', error);
      setError('Failed to add content to playlist. Please try again.');
    }
  };

  const handleRemoveContent = async (playlistId, contentId) => {
    try {
      await removeContentFromPlaylist(playlistId, contentId);
      fetchPlaylists();
    } catch (error) {
      console.error('Error removing content from playlist:', error);
      setError('Failed to remove content from playlist. Please try again.');
    }
  };

  const onDragEnd = async (result, playlistId) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const playlist = playlists.find(p => p.id === playlistId);
    const newItems = Array.from(playlist.Contents);
    const [reorderedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);

    try {
      await reorderPlaylistContent(playlistId, { contentOrder: newItems.map(item => item.id) });
      fetchPlaylists();
    } catch (error) {
      console.error('Error reordering playlist content:', error);
      setError('Failed to reorder playlist content. Please try again.');
    }
  };

  const renderContentThumbnail = (content) => {
    const IconComponent = content.type === 'image' ? FiImage : content.type === 'video' ? FiFilm : FiMusic;
    return (
      <div className="relative w-full h-24 bg-gradient-to-br from-indigo-500 to-purple-600 rounded-lg overflow-hidden group">
        {content.type === 'image' && content.filePath && (
          <img src={content.filePath} alt={content.title} className="w-full h-full object-cover opacity-75 group-hover:opacity-100 transition-opacity duration-300" />
        )}
        {content.type === 'video' && (
          <div className="w-full h-full flex items-center justify-center bg-black">
            <FiPlay className="text-white text-3xl" />
          </div>
        )}
        <IconComponent className="absolute top-2 right-2 text-white text-xl" />
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-300">
          <p className="text-white text-sm font-medium truncate px-2">{content.title}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-br from-gray-100 to-gray-200 min-h-screen p-8">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-5xl mx-auto"
      >
        <h1 className="text-4xl font-bold mb-8 text-gray-800">Your Playlists</h1>

        <form onSubmit={handleCreatePlaylist} className="mb-8 flex">
          <input
            type="text"
            value={newPlaylistName}
            onChange={(e) => setNewPlaylistName(e.target.value)}
            placeholder="Enter new playlist name"
            className="flex-grow shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-l-full py-3 px-6"
          />
          <button type="submit" className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-r-full shadow-sm text-white bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300">
            <FiPlus className="mr-2" /> Create Playlist
          </button>
        </form>

        {error && (
          <motion.p 
            className="text-red-500 mb-4 bg-red-100 p-3 rounded-lg"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </motion.p>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <motion.div 
              className="w-16 h-16 border-t-4 border-indigo-500 border-solid rounded-full"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            />
          </div>
        ) : (
          <AnimatePresence>
            {playlists.map(playlist => (
              <motion.div
                key={playlist.id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="bg-white shadow-lg rounded-lg overflow-hidden mb-6"
              >
                <div className="px-6 py-4 flex justify-between items-center">
                  <h3 className="text-xl font-semibold text-gray-800">{playlist.name}</h3>
                  <div className="flex items-center space-x-2">
                    <button 
                      onClick={() => setExpandedPlaylist(expandedPlaylist === playlist.id ? null : playlist.id)}
                      className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
                    >
                      {expandedPlaylist === playlist.id ? <FiChevronUp /> : <FiChevronDown />}
                    </button>
                    <button 
                      onClick={() => handleDeletePlaylist(playlist.id)} 
                      className="text-red-600 hover:text-red-800 transition-colors duration-200"
                    >
                      <FiTrash2 />
                    </button>
                  </div>
                </div>
                <AnimatePresence>
                  {expandedPlaylist === playlist.id && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <DragDropContext onDragEnd={(result) => onDragEnd(result, playlist.id)}>
                        <Droppable droppableId={`playlist-${playlist.id}`}>
                          {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef} className="px-6 py-4 space-y-2">
                             {playlist.Contents && playlist.Contents.length > 0 ? (
  playlist.Contents
    .sort((a, b) => a.order - b.order)
    .map((content, index) => (
      <li key={content.id} className="bg-gray-50 rounded-lg overflow-hidden shadow-sm group flex items-center p-2 mb-2">
        <div className="flex-shrink-0 w-12 mr-2">
          <input
            type="number"
            min="1"
            max={playlist.Contents.length}
            value={content.order}
            onChange={(e) => handleReorderContent(playlist.id, content.id, parseInt(e.target.value))}
            className="w-full px-2 py-1 text-sm border rounded focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="flex-shrink-0 w-24 mr-4">
          {renderContentThumbnail(content)}
        </div>
        <div className="flex-grow">
          <p className="text-sm font-medium text-gray-900 truncate">{content.title}</p>
          <p className="text-xs text-gray-500">{content.type}</p>
        </div>
        <div className="flex-shrink-0 flex items-center">
          <button onClick={() => handleRemoveContent(playlist.id, content.id)} className="text-red-600 hover:text-red-800 transition-colors duration-200">
            <FiTrash2 />
          </button>
        </div>
      </li>
    ))
) : (
  <li className="text-gray-500 text-center py-4">No content in this playlist</li>
)}
                              {provided.placeholder}
                            </ul>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <div className="px-6 py-4 bg-gray-50">
                        <button
                          onClick={() => setIsAddingContent(true)}
                          className="w-full px-4 py-2 text-sm font-medium text-indigo-600 bg-indigo-100 rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors duration-200"
                        >
                          Add Content
                        </button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </motion.div>

      <AnimatePresence>
        {isAddingContent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white rounded-lg w-full max-w-lg p-6 max-h-[80vh] overflow-y-auto"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Add Content to Playlist</h3>
                <button onClick={() => setIsAddingContent(false)} className="text-gray-400 hover:text-gray-500">
                  <FiX />
                </button>
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                {contents.map(content => (
                  <div
                    key={content.id}
                    onClick={() => {
                      handleAddContent(expandedPlaylist, content.id);
                      setIsAddingContent(false);
                    }}
                    className="cursor-pointer transition-transform duration-200 hover:scale-105"
                  >
                    {renderContentThumbnail(content)}
                  </div>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default PlaylistListPage;