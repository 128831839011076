import React, { useState, useEffect } from 'react';
import { getContent, getScreens, getPlaylists } from '../services/api';

function Dashboard() {
  const [contentCount, setContentCount] = useState(0);
  const [screenCount, setScreenCount] = useState(0);
  const [playlistCount, setPlaylistCount] = useState(0);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const [contentResponse, screenResponse, playlistResponse] = await Promise.all([
        getContent(),
        getScreens(),
        getPlaylists()
      ]);
      setContentCount(contentResponse.data.length);
      setScreenCount(screenResponse.data.length);
      setPlaylistCount(playlistResponse.data.length);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-blue-100 p-4 rounded shadow">
          <h2 className="text-xl font-semibold">Total Content</h2>
          <p className="text-3xl font-bold">{contentCount}</p>
        </div>
        <div className="bg-green-100 p-4 rounded shadow">
          <h2 className="text-xl font-semibold">Total Screens</h2>
          <p className="text-3xl font-bold">{screenCount}</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded shadow">
          <h2 className="text-xl font-semibold">Total Playlists</h2>
          <p className="text-3xl font-bold">{playlistCount}</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;