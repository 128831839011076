import React, { useState, useEffect } from 'react';
import { getScreens, registerScreen, deleteScreen, assignPlaylist, removePlaylistFromScreen, getPlaylists } from '../services/api';
import {  FiTrash2, FiCopy, FiEye, FiEyeOff, FiPlus, FiSearch, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

function ScreenList() {
  const [screens, setScreens] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [newScreen, setNewScreen] = useState({ name: '', orientation: 'horizontal' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPairingCode, setShowPairingCode] = useState({});
  const [isAddingScreen, setIsAddingScreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [screensPerPage] = useState(9);

  useEffect(() => {
    fetchScreens();
    fetchPlaylists();
  }, []);

  const fetchScreens = async () => {
    setIsLoading(true);
    try {
      const response = await getScreens();
      setScreens(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching screens:', error);
      setError('Failed to fetch screens. Please try again later.');
    }
    setIsLoading(false);
  };

  const fetchPlaylists = async () => {
    try {
      const response = await getPlaylists();
      setPlaylists(response.data);
    } catch (error) {
      console.error('Error fetching playlists:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewScreen(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await registerScreen(newScreen);
      setScreens(prevScreens => [...prevScreens, response.data.screen]);
      setNewScreen({ name: '', orientation: 'horizontal' });
      setError(null);
      setIsAddingScreen(false);
    } catch (error) {
      console.error('Error registering screen:', error);
      setError('Failed to register screen. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this screen?')) {
      setIsLoading(true);
      try {
        await deleteScreen(id);
        setScreens(screens.filter(screen => screen.id !== id));
      } catch (error) {
        console.error('Error deleting screen:', error);
        setError('Failed to delete screen. Please try again.');
      }
      setIsLoading(false);
    }
  };

  const handleAssignPlaylist = async (screenId, playlistId) => {
    setIsLoading(true);
    try {
      if (playlistId) {
        await assignPlaylist(screenId, playlistId);
        const updatedScreens = screens.map(screen => 
          screen.id === screenId 
            ? { ...screen, Playlists: [playlists.find(p => p.id === parseInt(playlistId))], Playlist: playlists.find(p => p.id === parseInt(playlistId)) }
            : screen
        );
        setScreens(updatedScreens);
      } else {
        const screenToUpdate = screens.find(s => s.id === screenId);
        if (screenToUpdate && (screenToUpdate.Playlists?.length > 0 || screenToUpdate.Playlist)) {
          await handleRemovePlaylist(screenId, screenToUpdate.Playlists?.[0]?.id || screenToUpdate.Playlist?.id);
        }
      }
      setError(null);
    } catch (error) {
      console.error('Error updating playlist assignment:', error);
      setError('Failed to update playlist assignment. Please try again.');
    }
    setIsLoading(false);
  };

  const handleRemovePlaylist = async (screenId, playlistId) => {
    setIsLoading(true);
    try {
      await removePlaylistFromScreen(screenId, playlistId);
      const updatedScreens = screens.map(screen => 
        screen.id === screenId 
          ? { ...screen, Playlists: [], Playlist: null, status: 'idle' }
          : screen
      );
      setScreens(updatedScreens);
      setError(null);
    } catch (error) {
      console.error('Error removing playlist from screen:', error);
      setError('Failed to remove playlist from screen. Please try again.');
    }
    setIsLoading(false);
  };

  const togglePairingCode = (screenId) => {
    setShowPairingCode(prev => ({ ...prev, [screenId]: !prev[screenId] }));
  };

  const formatPairingCode = (code) => {
    return `${code.slice(0, 3)} ${code.slice(3, 6)} ${code.slice(6)}`;
  };

  const copyPairingCode = (pairingCode) => {
    navigator.clipboard.writeText(pairingCode)
      .then(() => alert('Pairing code copied to clipboard!'))
      .catch(err => console.error('Failed to copy pairing code:', err));
  };

  const getAssignedPlaylistId = (screen) => {
    if (screen.Playlists && screen.Playlists.length > 0) {
      return screen.Playlists[0].id;
    } else if (screen.Playlist) {
      return screen.Playlist.id;
    } else {
      return '';
    }
  };

  const filteredScreens = screens.filter(screen =>
    screen.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastScreen = currentPage * screensPerPage;
  const indexOfFirstScreen = indexOfLastScreen - screensPerPage;
  const currentScreens = filteredScreens.slice(indexOfFirstScreen, indexOfLastScreen);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Screen Management</h1>
        <button
          onClick={() => setIsAddingScreen(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out"
        >
          <FiPlus className="inline-block mr-2" />
          Add Screen
        </button>
      </div>

      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search screens..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <FiSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      {error && <p className="text-red-500 mb-6 text-center bg-red-100 border border-red-400 rounded-lg p-3">{error}</p>}

      {isAddingScreen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md relative">
            <h2 className="text-2xl font-bold mb-4">Add New Screen</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Screen Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={newScreen.name}
                  onChange={handleInputChange}
                  placeholder="Enter screen name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="orientation">
                  Orientation
                </label>
                <select
                  id="orientation"
                  name="orientation"
                  value={newScreen.orientation}
                  onChange={handleInputChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="horizontal">Horizontal ⬆️</option>
                  <option value="vertical">Vertical ⬅️ </option>
                </select>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsAddingScreen(false)}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
                >
                  Add Screen
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentScreens.map(screen => (
              <div key={screen.id} className="bg-white shadow-md rounded-lg overflow-hidden transition duration-300 ease-in-out hover:shadow-lg">
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="font-bold text-xl text-gray-800">{screen.name}</h2>
                    <span className={`px-2 py-1 rounded-full text-xs font-semibold ${screen.status === 'active' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                      {screen.status}
                    </span>
                  </div>
                  <p className="text-gray-600 text-sm mb-2">Orientation: {screen.orientation}</p>
                  <select
                    onChange={(e) => handleAssignPlaylist(screen.id, e.target.value)}
                    className="w-full mb-2 px-2 py-1 border rounded text-sm"
                    value={getAssignedPlaylistId(screen)}
                  >
                    <option value="">Assign Playlist</option>
                    {playlists.map(playlist => (
                      <option key={playlist.id} value={playlist.id}>{playlist.name}</option>
                    ))}
                  </select>
                  <div className="flex justify-between items-center text-sm">
                    <button 
                      onClick={() => togglePairingCode(screen.id)} 
                      className="text-blue-500 hover:text-blue-600 font-semibold focus:outline-none"
                    >
                      {showPairingCode[screen.id] ? <FiEyeOff className="inline-block mr-1" /> : <FiEye className="inline-block mr-1" />}
                      Pairing Code
                    </button>
                    <button 
                      onClick={() => handleDelete(screen.id)} 
                      className="text-red-500 hover:text-red-600 font-semibold focus:outline-none"
                    >
                      <FiTrash2 className="inline-block mr-1" />
                      Delete
                    </button>
                  </div>
                  {showPairingCode[screen.id] && (
                    <div className="mt-2 bg-gray-100 p-2 rounded-lg flex items-center justify-between text-sm">
                      <span className="font-mono">{formatPairingCode(screen.pairingCode)}</span>
                      <button
                        onClick={() => copyPairingCode(screen.pairingCode)}
                        className="text-blue-500 hover:text-blue-600 focus:outline-none"
                      >
                        <FiCopy />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8 flex justify-center">
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              {Array.from({ length: Math.ceil(filteredScreens.length / screensPerPage) }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                    currentPage === index + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  {index + 1}
                </button>
              ))}
        <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredScreens.length / screensPerPage)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <FiChevronRight className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </>
      )}
    </div>
  );
}

export default ScreenList;