import axios from 'axios';

const API_URL = 'http://15.184.3.224:3000/api';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const login = (email, password) => api.post('/auth/login', { email, password });
export const register = (username, email, password) => api.post('/auth/register', { username, email, password });
export const getProfile = () => api.get('/auth/profile');

export const getContent = () => api.get('/content');
export const updateContent = (id, data) => api.put(`/content/${id}`, data);
export const deleteContent = (id) => api.delete(`/content/${id}`);

export const getScreens = () => api.get('/screens');
export const registerScreen = (data) => api.post('/screens/register', data);
export const updateScreen = (id, data) => api.put(`/screens/${id}`, data);
export const deleteScreen = (id) => api.delete(`/screens/${id}`);
export const assignPlaylist = (screenId, playlistId) => api.post(`/screens/${screenId}/assign-playlist`, { playlistId });

export const getPlaylists = () => api.get('/playlists');
export const createPlaylist = (data) => api.post('/playlists', data);
export const deletePlaylist = (id) => api.delete(`/playlists/${id}`);

export const getPlaylistDetails = (id) => api.get(`/playlists/${id}`);
export const updatePlaylist = (id, data) => api.put(`/playlists/${id}`, data);
export const addContentToPlaylist = (id, data) => api.post(`/playlists/${id}/content`, data);
export const removeContentFromPlaylist = (playlistId, contentId) => api.delete(`/playlists/${playlistId}/content/${contentId}`);
export const reorderPlaylistContent = (id, data) => api.put(`/playlists/${id}/reorder`, data);
export const removePlaylistFromScreen = (screenId, playlistId) => api.delete(`/screens/${screenId}/playlist`, { data: { playlistId } });

export const createContent = (formData) => api.post('/content', formData, {
  headers: { 'Content-Type': 'multipart/form-data' }
});

export default api;
