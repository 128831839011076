import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white p-4 mt-auto">
      <div className="container mx-auto flex justify-between items-center">
        <p className="text-sm">© {new Date().getFullYear()} Build By Azzam in Riyadh</p>
      </div>
    </footer>
  );
}

export default Footer;
