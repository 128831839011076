import React, { useState, useEffect } from 'react';
import { getContent, createContent, deleteContent } from '../services/api';
import { FiUpload, FiTrash2, FiImage, FiVideo, FiPlus, FiX } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
const SUPPORTED_VIDEO_TYPES = ['video/mp4', 'video/quicktime', 'video/x-msvideo'];

function ContentList() {
  const [contents, setContents] = useState([]);
  const [newContent, setNewContent] = useState({ title: '', type: 'image', file: null });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [isAddingContent, setIsAddingContent] = useState(false);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    fetchContents();
  }, []);

  const fetchContents = async () => {
    setIsLoading(true);
    try {
      const response = await getContent();
      setContents(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching contents:', error);
      setError('Failed to fetch contents. Please try again later.');
    }
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'file') {
      handleFile(files[0]);
    } else {
      setNewContent(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleFile = (file) => {
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError('File size exceeds 10MB limit.');
        return;
      }
      if (!(SUPPORTED_IMAGE_TYPES.includes(file.type) || SUPPORTED_VIDEO_TYPES.includes(file.type))) {
        setError('Unsupported file type. Please upload a valid image or video file.');
        return;
      }
      setNewContent(prev => ({ ...prev, file: file, type: file.type.startsWith('image/') ? 'image' : 'video' }));
      setError(null);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      formData.append('title', newContent.title);
      formData.append('type', newContent.type);
      formData.append('file', newContent.file);

      const response = await createContent(formData);
      setContents([...contents, response.data]);
      setNewContent({ title: '', type: 'image', file: null });
      setIsAddingContent(false);
    } catch (error) {
      console.error('Error creating content:', error);
      setError('Failed to create content. Please try again.');
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this content?')) {
      setIsLoading(true);
      try {
        await deleteContent(id);
        setContents(contents.filter(content => content.id !== id));
      } catch (error) {
        console.error('Error deleting content:', error);
        setError(error.response?.data?.message || 'Failed to delete content. Please try again.');
      }
      setIsLoading(false);
    }
  };

  const filteredContents = filter === 'all' ? contents : contents.filter(content => content.type === filter);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">Content Management</h1>
        <button
          onClick={() => setIsAddingContent(true)}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <FiPlus className="inline-block mr-2" />
          Add Content 📷
        </button>
      </div>

      <div className="mb-6 flex justify-center space-x-4">
        <button
          onClick={() => setFilter('all')}
          className={`px-4 py-2 rounded-full transition duration-300 ease-in-out ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
        >
          All
        </button>
        <button
          onClick={() => setFilter('image')}
          className={`px-4 py-2 rounded-full transition duration-300 ease-in-out ${filter === 'image' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
        >
          Images
        </button>
        <button
          onClick={() => setFilter('video')}
          className={`px-4 py-2 rounded-full transition duration-300 ease-in-out ${filter === 'video' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
        >
          Videos
        </button>
      </div>

      {error && <p className="text-red-500 mb-6 text-center">{error}</p>}
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
        </div>
      ) : (
        <motion.div layout className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          <AnimatePresence>
            {filteredContents.map(content => (
              <motion.div
                key={content.id}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="bg-white shadow-lg rounded-lg overflow-hidden"
              >
                <div className="relative aspect-w-16 aspect-h-9">
                  {content.type === 'image' ? (
                    <img src={content.filePath} alt={content.title} className="w-full h-full object-cover" />
                  ) : (
                    <video src={content.filePath} className="w-full h-full object-cover" controls />
                  )}
                  <div className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md">
                    {content.type === 'image' ? <FiImage className="text-blue-500" /> : <FiVideo className="text-blue-500" />}
                  </div>
                </div>
                <div className="p-4">
                  <h2 className="font-bold text-lg mb-2 text-gray-800 truncate">{content.title}</h2>
                  <button 
                    onClick={() => handleDelete(content.id)} 
                    className="mt-2 w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center justify-center"
                  >
                    <FiTrash2 className="mr-2" /> Delete
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      )}

      <AnimatePresence>
        {isAddingContent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white rounded-lg p-8 max-w-md w-full shadow-lg"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800">Add New Content</h2>
                <button 
                  onClick={() => setIsAddingContent(false)} 
                  className="text-gray-600 hover:text-gray-800 transition duration-300 ease-in-out"
                  aria-label="Close"
                >
                  <FiX size={24} />
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="title" className="block text-gray-700 text-sm font-semibold mb-2">Title:</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={newContent.title}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div
                  className={`border-2 border-dashed rounded-lg p-4 ${dragActive ? 'border-blue-500 bg-blue-100' : 'border-gray-300'} flex items-center justify-center`}
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  onDragLeave={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    id="file"
                    name="file"
                    accept="image/*,video/*"
                    onChange={handleInputChange}
                    className="hidden"
                    required
                  />
                  <label htmlFor="file" className="cursor-pointer text-gray-700 flex flex-col items-center">
                    <FiUpload size={48} className="mb-2" />
                    <span className="text-sm">Drag & drop a file here or click to select</span>
                  </label>
                </div>
                {error && <p className="text-red-500 mt-2">{error}</p>}
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                  >
                    Save
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ContentList;
